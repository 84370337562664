import { Atom, Cmp, Mod } from ":mods";
import { updateShowExhibition } from "../apis/show-exhibition";

export function ExhibitionDescription({ stage_id, description }) {
  const onSubmit = async (e: Event) => {
    e.preventDefault();

    const payload = {
      key: "box-06",
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(payload));
    await updateShowExhibition(stage_id, formData)
      .then((message) => {
        console.log(message, " :message");
        Cmp.Alert.actions.pushToast({
          type: "success",
          message: "Exhibition data submitted successfully",
        });
      })
      .catch((e) => {
        const err = e.response?.data?.error?.[0] ?? e.response?.data?.error ?? "An Unknown Error Occured";
        console.log(err);
        Cmp.Alert.actions.pushToast({
          type: "error",
          message: err,
        });
      });
  };

  return (
    <section>
      <div id="tip-container" class="flex gap-10px py-10px pr-10px leading-22.4px text-justify">
        <p>
          {description ||
            "Once you have finished your idea, press the button below to be taken to a quick survey. We will then ask you for some more information about your knowledge and experience in order to effectively judge your entry. You need to complete both of these in order to enter the challenge."}
        </p>
      </div>
      <div class="flex justify-start pr-5px py-10px">
        <Atom.Form.Button
          controls={[]}
          class="form-btn px-4 py-1 !w-fit"
          statusValid={"Submit"}
          onlyStatus={["valid", "submit"]}
          onclick={onSubmit}
          classes={{
            spinner: "!w-80px",
          }}
        />
      </div>
    </section>
  );
}
